<script setup lang="ts">
    import NotificationLog from '~/app/common/models/NotificationLog';

    const props = defineProps({
  notificationLog: null,
  size: { default: 'min-w-[100px] px-2 py-1 md:py-2 md:px-3 lg:px-5 font-semibold text-[11px] uppercase md:normal-case md:text-[12px]' }
});

    const badgeClass = computed(() => {
        return `${props.size} ${useStatusBackgroundColour(props.notificationLog.status)}`;
    });
</script>

<template>
    <span :class="`whitespace-no-wrap inline-block text-center rounded-full leading-normal ${badgeClass}`">
        {{ $t(`misc.notifications_status.${notificationLog.status}`) }}
    </span>
</template>
